import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { fetcher } from "lib/fetcher";
import { getUser } from "lib/prismaQueries/getUser";
import { useSession } from "next-auth/react";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";

type Props = {
  activeSession: boolean;
  hasNextMonthDates: boolean;
};

export const UserInfoBanner = ({ activeSession, hasNextMonthDates }: Props) => {
  const { data: session } = useSession();
  const router = useRouter();
  const { t } = useTranslation("common");
  const uid = session?.user && session?.user.id;

  const { data: user, isLoading } = useQuery(
    ["user", uid],
    () =>
      fetcher<Awaited<ReturnType<typeof getUser>>>(`/api/users/${Number(uid)}`),
    { enabled: !!activeSession }
  );

  // get the name of the current month to show the user
  const month = new Date().toLocaleString(router.locale, {
    month: "long",
  });
  // get the name of the next month to show the user
  const nextMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    1
  ).toLocaleString(router.locale, {
    month: "long",
  });

  const { data: benefitStatus, isLoading: isLoadingBenefits } = useQuery(
    [`benefits`, uid],
    () =>
      fetcher<{ availableThis: number; availableNext: number; status: string }>(
        `/api/benefits/status`
      ),
    { enabled: !!activeSession && !!session?.user?.benefits }
  );

  const countSum = () => {
    let currentPrice = 0;
    user?.BookEntry.forEach((entry) => {
      const price = user?.reducedPrice
        ? Number(entry.entry.price) * 0.6
        : Number(entry.entry.price);
      !entry.paid ? (currentPrice += price) : "";
    });
    return currentPrice;
  };

  const balance = countSum() !== 0 ? countSum() * -1 : 0;

  return (
    <div className="my-6 mb-6 justify-center">
      <div
        className={clsx(
          "relative w-full  rounded-[5px] bg-gradient-to-r from-indigo-600/60 to-emerald-600/60 p-6 text-xl text-white"
        )}
      >
        <div className="flex justify-between">
          <div className="flex items-center justify-center text-4xl">
            {`${process.env.NEXT_PUBLIC_LOCATION}`}{" "}
          </div>
          {activeSession ? (
            <div className="items-left flex flex-col justify-center">
              <div>
                <span className="mr-2">Kontostand:</span>
                <span>
                  {new Intl.NumberFormat(router.locale, {
                    style: "currency",
                    currency: "EUR",
                  }).format(balance)}{" "}
                </span>
              </div>
              {session?.user?.benefits ? (
                <div>
                  <div className="text-sm">
                    <div>
                      {t("remainingBenefits")} {month}:{" "}
                      <span className="font-bold">
                        {benefitStatus?.availableThis}
                      </span>
                    </div>
                    {hasNextMonthDates ? (
                      <div>
                        {t("remainingBenefits")} {nextMonth}:{" "}
                        <span className="font-bold">
                          {benefitStatus?.availableNext}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {balance < 0 &&
              process.env.NEXT_PUBLIC_PAYPAL_ACTIVE === "true" ? (
                <div className="text-xs">
                  <Link
                    target="_blank"
                    className="relative inline-block text-sm font-light font-black leading-6 text-white underline"
                    href={`${process.env.NEXT_PUBLIC_PAYPAL}${countSum()}`}
                  >
                    {t("paypalPay")}
                  </Link>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserInfoBanner;
